<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <div class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>

          <h1 class="page_title">{{ $route.meta.title }}</h1>
        </div>

        <main v-if="is_API_loaded || isNewPage" class="space-y-5">
          <!-- 1. -->
          <!-- 如果是新增分頁，就隱藏此區塊 -->

          <div v-if="!isNewPage" class="flex flex-wrap w-full items-center justify-between">
            <h1 class="text-xl font-medium text-gray-text_dark">{{ title }}</h1>

            <div class="flex items-center space-x-2 flex-shrink-0">
              <a :href="_wlink" target="_blank">
                <vs-button size="large" transparent>
                  <svg-icon icon-class="eye_view" className="primary xl"></svg-icon>
                  <span class="font-medium">檢視</span>
                </vs-button>
              </a>

              <vs-button @click="copy_dialog = true" size="large" transparent>
                <svg-icon icon-class="copy" className="primary"></svg-icon>
                <span class="font-medium ml-1">複製</span>
              </vs-button>
            </div>
          </div>
          <!-- <h1 v-if="!isNewPage" class="text-xl font-medium text-gray-text_dark lg:hidden">{{ title }}</h1> -->

          <!-- 新增分頁後顯示 -->
          <transition name="fade">
            <section v-if="alertAfterCreated">
              <vs-alert color="success" gradient>
                <template #icon>
                  <svg-icon icon-class="success" className="xxl"></svg-icon>
                </template>
                <template #title> {{ alertTitle }} 已新增成功 </template>
                <a :href="_wlink" target="_blank">在前台中檢視</a> 或 <router-link :to="{ name: 'menus' }">加入目錄選單</router-link>
              </vs-alert>
            </section>
          </transition>

          <!-- 2. -->
          <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
            <!-- 2.1.1 -->
            <div class="w-full md:w-14/20 space-y-4 flex-shrink-0">
              <article class="box">
                <div>
                  <h1 class="title">標題 *</h1>
                  <div class="relative">
                    <ValidationProvider mode="aggressive" name="標題" rules="required|max:50" v-slot="{ errors }">
                      <vs-input @input="titleChange" class="w-full" v-model="title" />
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                </div>

                <div>
                  <h1 class="title">內容</h1>
                  <editor ref="editor" v-model="content" :content="content" @input="contentChange"></editor>
                </div>
              </article>

              <!-- 2.1.2 -->
              <article class="box overflow-hidden" :class="[seoSectionOpen ? '' : 'h-16']">
                <div class="flex items-center justify-between">
                  <h1 class="bigTitle">SEO 搜尋引擎最佳化</h1>
                  <vs-button v-if="!seoSectionOpen" @click="seoSectionOpen = true" transparent color="success">編輯 SEO</vs-button>
                </div>

                <section
                  class="transition-transform duration-100 transform scale-y-0 origin-top space-y-6"
                  :class="[seoSectionOpen ? 'scale-y-100' : '']"
                >
                  <div class="space-y-1">
                    <h1 class="text-success font-medium text-base">{{ seoTitle }}</h1>
                    <p class="text-green-700 text-sm break-all" v-if="seoUrl">
                      {{ $store.state.getWebData.param.wlink }}/pages/{{ seoUrl }}
                    </p>
                    <p class="text-sm text-limit-2" style="max-height: 2.5em">{{ _seoContent }}</p>
                  </div>
                  <div>
                    <h1 class="title">網頁標題</h1>
                    <ValidationProvider mode="aggressive" name="網頁標題" rules="max:100" v-slot="{ errors }">
                      <vs-input class="w-full" v-model="seoTitle" />
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>

                  <div>
                    <h1 class="title">網頁網址</h1>
                    <div class="flex items-center border border-gray-border rounded-xl pl-3">
                      <div>
                        <span>{{ $store.state.getWebData.param.wlink }}/pages/</span>
                      </div>
                      <ValidationProvider class="w-full" mode="aggressive" name="網頁網址" rules="max:100" v-slot="{ errors }">
                        <vs-input type="url" id="noBorderInput" class="w-full" v-model.trim="seoUrl" />
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div>
                    <h1 class="title">網頁描述</h1>

                    <!-- <ValidationProvider class="w-full" mode="aggressive" name="網頁描述" rules="max:200" v-slot="{ errors }"> -->
                    <myTextarea autoHeight v-model="_seoContent"></myTextarea>
                    <!-- <errorDiv>{{ errors[0] }}</errorDiv> -->
                    <!-- </ValidationProvider> -->
                  </div>
                </section>
              </article>
            </div>

            <!-- 2.2 -->
            <div class="flex-grow space-y-4">
              <div class="box">
                <h1 class="bigTitle">公開狀態</h1>
                <div class="flex flex-col items-start space-y-2">
                  <!-- 判斷當type = 1 禁用 -->
                  <vs-radio :disabled="type === 1" v-model="isPublic" :val="1">公開</vs-radio>
                  <vs-radio :disabled="type === 1" v-model="isPublic" :val="0">隱藏<span v-if="isSchedule"> (已排程)</span></vs-radio>
                </div>
                <div key="1" v-if="!isPublic" class="flex flex-col items-end space-y-6">
                  <vs-button key="2" v-if="!schedule" @click="setSchedule" transparent color="success"> 設定排程 </vs-button>

                  <div key="3" v-if="schedule" class="space-y-6 pt-6 w-full">
                    <vs-input type="date" :min="_minDate" :max="_maxDate" v-model="date" label="公開日期" />
                    <vs-input type="time" v-model="time" label="公開時間" />
                  </div>
                  <vs-button key="4" v-if="schedule" @click="cancelSchedule" transparent color="success">取消排程</vs-button>
                </div>
              </div>
              <div class="box">
                <h1 class="bigTitle">功能模組</h1>
                <div class="space-y-1">
                  <vs-checkbox v-model="isTable">聯絡表單</vs-checkbox>
                  <vs-checkbox v-model="isAddress"> 地圖 </vs-checkbox>
                </div>
                <div>
                  <div class="relative" v-if="isAddress">
                    <ValidationProvider mode="aggressive" name="地址" :rules="`${isAddress ? 'required|max:100' : ''}`" v-slot="{ errors }">
                      <vs-input class="pt-5" label="地址" placeholder="例如：台北市信義區信義路五段7號" v-model.trim="address"></vs-input>
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                    <iframe
                      class="mt-4 rounded-xl"
                      v-if="address"
                      width="100%"
                      height="200"
                      style="border: 0"
                      loading="lazy"
                      allowfullscreen
                      :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyCO1kWn5gusfwKabYXEwBKaB1btJfxHyWI&q=${address}`"
                    >
                    </iframe>
                  </div>
                </div>
                <p>替此分頁添加自定義功能。</p>
              </div>
            </div>
          </section>

          <hr />

          <section class="flex items-center justify-between">
            <!-- 判斷當type = 1 禁用 -->
            <vs-button @click="deletePage_dialog = true" v-if="!isNewPage && type === 0" size="large" color="danger" border>
              <span class="font-medium">刪除分頁</span>
            </vs-button>
            <vs-button to="/admin/pages" v-else size="large" border>
              <span class="font-medium">取消</span>
            </vs-button>
            <vs-button size="large" ref="savePage" :disabled="invalid" @click="savePage(invalid)">
              <span class="font-medium">儲存分頁</span>
            </vs-button>
          </section>
        </main>
      </div>
    </ValidationObserver>

    <!-- 骨架屏 -->
    <main v-if="!is_API_loaded && !isNewPage" class="router-view-container space-y-4">
      <section class="flex items-center space-x-4 justify-between">
        <skeleton width="48px" />

        <div v-if="!isNewPage" class="flex lg:w-full items-center justify-between">
          <skeleton class="hidden lg:block" width="50%" height="40px" />
          <div class="flex items-center space-x-2">
            <skeleton width="82px" />
            <skeleton width="82px" />
          </div>
        </div>
      </section>
      <skeleton v-if="!isNewPage" class="lg:hidden" width="80%" height="40px" />

      <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
        <div class="w-full md:w-14/20 space-y-4">
          <article class="box">
            <skeleton />

            <div>
              <skeleton height="200px" />
            </div>
          </article>

          <article class="box">
            <skeleton />
          </article>
        </div>

        <div class="flex-grow space-y-4">
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
        </div>
      </section>

      <hr />

      <section class="flex items-center justify-between">
        <skeleton width="82px" />
        <skeleton width="82px" />
      </section>
    </main>

    <!-- 刪除分頁 dialog -->
    <vs-dialog overflow-hidden blur v-model="deletePage_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除此分頁？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的分頁無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="deletePage_dialog = false">取消</vs-button>
          <vs-button @click="deletePage" size="large" transparent color="danger" ref="deletePageBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>

    <!-- 刪除留言 dialog -->
    <vs-dialog overflow-hidden blur v-model="deleteMsg_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除 {{ formSelected.length }} 筆留言？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的留言無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="deleteMsg_dialog = false">取消</vs-button>
          <vs-button @click="deleteMsg" size="large" transparent color="danger" ref="deleteMsgBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>

    <!-- 複製分頁 dialog -->
    <vs-dialog overflow-hidden blur v-model="copy_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要複製此分頁？</h1>
      </template>
      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="copy_dialog = false">取消</vs-button>
          <vs-button @click="copyPage" size="large" transparent ref="copyMsgBtn" color="success">複製</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import editor from '@/components/editor.vue'
export default {
  name: 'page',
  components: {
    editor,
  },
  data() {
    return {
      is_API_loaded: false,
      isNewPage: false,
      title: '',
      content: '',
      seoTitle: '',
      seoUrl: '',
      seoContent: '',
      seoSectionOpen: false,
      titleSync: true,
      urlSync: true,
      contentSync: true,
      isPublic: 1,
      isSchedule: false, // 判斷此分頁有沒有排程
      schedule: false, // 設定排程按鈕
      date: '2000-01-01',
      time: '23:59',
      isAddress: false,
      isTable: '',
      address: '',
      addressBackup: '',
      deletePage_dialog: false,
      alertAfterCreated: false, // 新增成功後呈現alert訊息
      alertTitle: '',
      formMsg: [], // 表單留言訊息
      formSelected: [], // 被選擇的表單訊息
      formSelectAll: false, // 全選
      formIndeterminate: false, // checkbox中間狀態
      deleteMsg_dialog: false, // 刪除留言dialog
      formOpen: false, // 展開/隱藏留言區塊
      copy_dialog: false, // 複製dialog
      wlink: '',
      type: 0,
    }
  },
  computed: {
    _istable() {
      return this.isTable ? 1 : 0
    },
    _dateTime() {
      if (this.isPublic || this.schedule) {
        return this.date + ' ' + this.time + ':00'
      } else {
        return '2099-12-31' + ' ' + '23:59' + ':59'
      }
    },
    _minDate() {
      // 可選擇的最小日期
      const t = new Date()

      let year = t.getFullYear()
      let month = t.getMonth() + 1
      let date = t.getDate()
      if (date < 10) date = `0${date}`
      if (month < 10) month = `0${month}`
      return `${year}-${month}-${date}`
    },
    _maxDate() {
      // 可選擇的最大日期
      let now = Date.now() + 1000 * 60 * 60 * 24 * 365 * 10 // 獲取現在毫秒數 + 10年

      const t = new Date(now)

      let year = t.getFullYear()
      let month = t.getMonth() + 1
      let date = t.getDate()
      if (date < 10) date = `0${date}`
      if (month < 10) month = `0${month}`
      return `${year}-${month}-${date}`
    },
    // 把多選的checkbox陣列轉字串
    _selectedToStr() {
      return this.formSelected.toString()
    },
    _seoContent: {
      get: function () {
        return this.seoContent
          .replace(/<[^>]+>/g, '') // html標籤去除
          .replace(/&nbsp;/g, ' ') // &nbsp;轉空白
          .substr(0, 200) // 限制200字
      },
      set: function (val) {
        this.seoContent = val
      },
    },
    _wlink() {
      return this.$store.state.getWebData.param.wlink + this.wlink
    },
  },
  watch: {
    $route(to, from) {
      this.isNewPageFunc()
    },
    seoTitle(val) {
      if (val !== this.title) this.titleSync = false
      if (val === '') {
        // this.seoTitle = this.title
        this.titleSync = true
      }
    },
    seoUrl(val) {
      if (val !== this.title || this.isNewPage === false) this.urlSync = false
      if (val === '') {
        // this.seoUrl = this.title
        this.urlSync = true
      }
    },
    seoContent(val) {
      if (val !== this.content) this.contentSync = false
      if (val === '') {
        // this.seoContent = this.content
        this.contentSync = true
      }
    },
    isPublic(val) {
      if (val) {
        this.date = '2000-01-01'
        this.time = '23:59'
        this.schedule = false
        this.isSchedule = false
      }
    },
    isAddress(val) {
      if (!val) {
        this.addressBackup = this.address
        this.address = ''
      } else {
        this.address = this.addressBackup
      }
    },
  },
  created() {
    this.isNewPageFunc()
  },
  methods: {
    isNewPageFunc() {
      // 判斷是新增分頁 還是 編輯分頁
      this.$route.name === 'new-page' ? (this.isNewPage = true) : (this.isNewPage = false)

      if (!this.isNewPage) this.loadPage()
    },
    titleChange(value) {
      // 若sync = true，賦值到seo
      if (this.titleSync) this.seoTitle = value
      if (this.urlSync) this.seoUrl = value
    },
    contentChange(value) {
      // 若sync = true，賦值到seo
      if (this.contentSync) this.seoContent = value
    },
    // 設定排程 取得現在時間
    setSchedule() {
      this.schedule = true

      let now = Date.now() + 1000 * 60 * 30 // 獲取現在毫秒數 + 30分鐘

      const t = new Date(now)

      let year = t.getFullYear()
      let month = t.getMonth() + 1
      let date = t.getDate()
      let hour = t.getHours()
      let min = t.getMinutes()

      if (date < 10) date = `0${date}`
      if (month < 10) month = `0${month}`
      if (min < 10) min = `0${min}`

      this.date = `${year}-${month}-${date}`
      this.time = `${hour}:${min}`
    },
    // 取消排程
    cancelSchedule() {
      this.schedule = false
      this.isSchedule = false
      this.date = '2099-12-31'
      this.time = '23:59'
    },
    // 2.4.2 網站分頁-讀取
    loadPage() {
      this.$axios({
        url: 'front/pages/onepages/getPagesUnit.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          spno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.is_API_loaded = true
          let data2 = res.data.Data.data2

          // 時間
          let dt = data2.rtime.split(' ')
          this.date = dt[0]
          // let index = dt[1].lastIndexOf(':') // 抓出最後一個:索引
          // this.time = dt[1].slice(0, index) // 只保留0~index中的字
          this.time = dt[1]
          //

          // 若有排程，設定的時間預設打開
          if (data2.isrtime === 0 && dt[0] !== '2099-12-31') {
            this.isSchedule = true
            this.schedule = true
          }

          this.isPublic = data2.isrtime

          // 地址有資料才打勾
          this.address = data2.address
          this.addressBackup = data2.address
          if (data2.address) this.isAddress = true
          //
          //
          data2.istable ? (this.isTable = true) : (this.isTable = false)
          this.title = data2.title
          this.content = data2.cont
          this.seoTitle = data2.mtitle
          this.seoContent = data2.mdescs
          this.seoUrl = data2.mpages
          this.wlink = data2.wlink
          this.type = data2.type
          this.formMsg = res.data.Data.data3
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 2.4.3 網站分頁-新增
    // 2.4.4 網站分頁-修改
    savePage(invalid) {
      this.alertAfterCreated = false
      // 如果驗證未通過，return
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.savePage,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let url = '' // api url

      let mdescs = '' // seo網頁描述
      if (this._seoContent === '') {
        // 若seo網頁描述被清空，把內容塞回去
        mdescs = this.content
          .replace(/<[^>]+>/g, '') // html標籤去除
          .replace(/&nbsp;/g, ' ') // &nbsp;轉空白
          .substr(0, 200) // 限制200字
      } else {
        mdescs = this._seoContent
      }

      let mpages = '' // 網頁網址
      if (this.seoUrl === '') {
        mpages = this.title.replace(/[&|\*^'"{}[\]%$:;=#@!/?\s*\\\\<>,.()~+_`]/g, '-')
      } else {
        mpages = this.seoUrl.replace(/[&|\*^'"{}[\]%$:;=#@!/?\s*\\\\<>,.()~+_`]/g, '-')
      }

      let data = {
        lang: 0,
        rtime: this._dateTime,
        title: this.title,
        cont: this.content,
        address: this.address,
        istable: this._istable,
        mtitle: this.seoTitle ? this.seoTitle : (this.seoTitle = this.title),
        mdescs: mdescs,
        mpages: mpages,
      }

      if (this.isNewPage) {
        url = 'front/pages/onepages/setPages.php'
      } else {
        url = 'front/pages/onepages/uPages.php'
        data.spno = this.$route.params.id
      }

      this.$axios({
        url: url,
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: data,
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()

          this.$myVS.openNoti(res.data.Message, '', undefined)

          // 若是新增
          if (this.isNewPage) {
            this.alertAfterCreated = true // 呈現alert
            this.alertTitle = this.title
            // this.$router.replace({
            //   // 跳轉到新頁面
            //   name: 'edit-page',
            //   params: {
            //     id: res.data.Data.spno,
            //   },
            // })
            setTimeout(() => {
              this.$router.replace({
                name: 'pages',
              })
            }, 0)
          } else {
            // this.loadPage()
            setTimeout(() => {
              this.$router.replace({
                name: 'pages',
              })
            }, 0)
          }
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 刪除分頁
    deletePage() {
      const loading = this.$vs.loading({
        target: this.$refs.deletePageBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/pages/onepages/dPages.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          spno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          loading.close() // 關閉按鈕loading
          this.deletePage_dialog = false // 關閉dialog
          this.$myVS.openNoti(res.data.Message, '', undefined)

          setTimeout(() => {
            this.$router.replace({
              name: 'pages',
            })
          }, 0)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
    // 留言checkbox選中觸發
    checkBoxHandleClick(e) {
      setTimeout(() => {
        if (this.formSelected.length > 0 && this.formSelected.length < this.formMsg.length) {
          // 勾選卻沒有全選時
          this.formIndeterminate = true
        } else if (this.formSelected.length === this.formMsg.length) {
          // 全選時
          this.formIndeterminate = false
          this.$nextTick(() => {
            this.formSelectAll = true
          })
        } else {
          // 沒選時
          this.formIndeterminate = false
          this.formSelectAll = false
        }
      }, 0)
    },
    // 中間狀態checkbox選中觸發
    indeterminateHandleClick(e) {
      let value = e.target.checked
      this.checkAllHandleClick(null, value)
    },
    // 全選checkbox選中觸發
    checkAllHandleClick(e, status) {
      let value
      e ? (value = e.target.checked) : (value = status)
      if (value) {
        for (let i = 0; i < this.formMsg.length; i++) {
          this.formSelected.push(this.formMsg[i].spino)
        }
      } else {
        this.formSelected = []
      }
    },
    // 刪除留言
    deleteMsg() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteMsgBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/pages/onepages/dPagesInForm.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          spino: this._selectedToStr,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.formSelected = []
          this.formIndeterminate = false
          this.formSelectAll = false
          loading.close() // 關閉按鈕loading
          this.deleteMsg_dialog = false // 關閉dialog
          this.$myVS.openNoti(res.data.Message, '', undefined)

          // 重新讀取留言資料
          this.loadForm()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
    // 2.4.8 網站分頁-讀取表單列表
    loadForm() {
      this.$axios({
        url: 'front/pages/onepages/getPagesInFormList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          spno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.formMsg = res.data.Data.data3
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 2.4.6 網站分頁-複製
    copyPage() {
      const loading = this.$vs.loading({
        target: this.$refs.copyMsgBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/pages/onepages/copyPages.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          spno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          loading.close() // 關閉按鈕loading
          this.copy_dialog = false // 關閉dialog
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.$router.push({
            name: 'pages',
          })
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  @apply bg-white rounded-xl p-4 space-y-6;
}

.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}

.replyMsg_section {
  @apply px-4 mr-0 ml-auto space-y-4 relative;
  width: calc(100% - 23px - 16px);

  // 直線
  &::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background: var(--gray-divide);
    left: calc(0px - 11.5px);
  }

  .replied_section {
    position: relative;
    // 彎曲的線
    &::before {
      content: '';
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      border: 2px solid var(--gray-divide);
      clip-path: circle(55% at 0 100%);
      left: -27px;
      top: 30%;
    }
  }
}

::v-deep #vs-input--noBorderInput {
  border: none;
  padding-left: 0px;
}

::v-deep .formCheckbox .vs-checkbox-label {
  padding-left: 16px;
}
</style>
